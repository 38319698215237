var angular = require('angular');

angular.module('LoginApp', [require('angular-material'), require('angular-animate'), require('angular-messages'), require('angular-aria')])
    .controller('LoginCtrl', LoginCtrl)
    .config(materialDesignConfig)
    .config(function($sceProvider) {
        // Wegen 
        // Error: [$sce:insecurl] Blocked loading resource from url not allowed by $sceDelegate policy.  URL: https://static.nts.ch/images/ntslogo_text_negative.svg
        $sceProvider.enabled(false);
    })
    .directive('formIcon', formIcon);
    

materialDesignConfig.$inject = ['$mdThemingProvider'];
function materialDesignConfig($mdThemingProvider) {
    $mdThemingProvider.definePalette('ntsPalette', {
        '50': '000000',
        '100': '0a0a0a',
        '200': '151514',
        '300': '1f1f1e', // <-- hue-1
        '400': '2a2a28',
        '500': '2f2f2d', // <-- defult primary
        '600': '3e3e3c',
        '700': '494946',
        '800': '535350', // <-- hue-2
        '900': '5e5e5a',
        'A100': '686864', // <-- hue-3
        'A200': '72726e',
        'A400': '7d7d78',
        'A700': '878782',
        'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
        // on this palette should be dark or light 
        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
        '200', '300', '400', 'A100'],
        'contrastLightColors': undefined,    // could also specify this if default was 'dark' 
        'default': '500'
    });

    $mdThemingProvider.definePalette('ntsAccentPalette', {
        '50': '80846c',
        '100': '899060',
        '200': 'dfe3c6',
        '300': '99a74a',
        '400': 'a2b43c',
        '500': 'aac030',  
        '600': 'dfe3c6',
        '700': 'dfe3c6',
        '800': 'dfe3c6',
        '900': 'dfe3c6',
        'A100': 'e7efbe', // <-- hue-1
        'A200': '9ba952', // <-- default accent
        'A400': 'c3d566', // <-- hue-2
        'A700': 'e9f99a', // <-- hue-3
        //'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
        // on this palette should be dark or light
        'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
         '200', '300', '400', 'A100'],
        'contrastLightColors': undefined,  // could also specify this if default was 'dark'  
        'default': '500'
    });
    $mdThemingProvider.theme('default').primaryPalette('ntsPalette').accentPalette('ntsAccentPalette');
}

LoginCtrl.$inject = ['$http', '$mdToast', '$sce'];
function LoginCtrl($http, $mdToast, $sce) {   
    
    this.ntslogo = 'https://static.nts.ch/images/ntslogo_text_negative.svg';

    this.hideAll = function() {
        this.hideLogin = true;
        this.hideResetPasswordStart = true;
        this.hideResetPassword = true;
        this.hideChangePasswordStart = true;
        this.hideChangePassword = true;
        this.hideChangePasswordResponse = true;
    }
    
    this.hideAll();
    
    // Toast
    //------
    this.error = typeof error === "undefined" ? false : error;
    this.message = typeof message === "undefined" ? '' : message;
    if (this.error && this.message !== '')
    {
        $mdToast.show(
            $mdToast.simple()
            .content(message)
            .position('bottom left')
            .hideDelay(80000)
        );
    }
    
    // Menu
    //------
    this.apps = typeof apps === "undefined" ? [] : apps;
    this.activeApp = typeof activeApp === "undefined" ? -1 : activeApp;
    this.user = typeof user === "undefined" ? {} : user;
    this.userinfo = typeof userinfo === "undefined" ? false : true;

    this.openMenu = function($mdOpenMenu, ev) {
      $mdOpenMenu(ev);
    };

    this.changePassword = function(ev) {
        this.hideAll();
        this.hideChangePassword = false;
    };
    
    this.RedirectToChangePassword = function(ev, key) {
        this.hideAll();
        document.location.replace('/?app=' + key)
    };

    this.logout = function(ev) {
        document.location.href = '/logout';
    };
    
    this.logoutforce = function(ev) {
        document.location.href = '/logouteverywhere';
    };

    // Login
    //-------
    if (typeof showLogin !== "undefined") {
        this.hideLogin = !showLogin;
    }

    this.loginSubmit = function () {
        var token = nts.crypto.token();
        window.localStorage.setItem("token", token);
        this.token = token;
    };
    
    // Reset Password
    //----------------
    if (typeof showResetPassword !== "undefined") {
        this.hideResetPassword = !showResetPassword;
    }
    
    this.resetPasswordStart = function () {
        this.hideAll();
        this.hideResetPasswordStart = false;
    }
    
    this.resetPasswordStop = function () {
        this.hideAll();
        this.hideLogin = false;
    }
    
    // Change Password
    //-----------------
    if (typeof showChangePassword !== "undefined") {
        this.hideChangePassword = !showChangePassword;
    }
    if (typeof showChangePasswordResponse !== "undefined") {
        this.hideChangePasswordResponse = !showChangePasswordResponse;
    }
    
    this.changePasswordStop = function () {
        if (this.activeApp !== -1)
        {
            document.location.href = this.apps[this.activeApp].url;
        } else {
            this.hideAll();
        }
    }
    
    this.changePasswordSubmit = function () {
        this.token = window.localStorage.getItem("token");
    };
}




function formIcon() {
    return {
        restrict: 'E',
        scope: {
            icon: "@",
            error   : "@",
        },
        template: '<md-icon class="{{class}} material-icons" style="font-size:80px; height:80px; width:80px;">{{iconname}}</md-icon>',
        link: function (scope, element) {
            if (scope.error === 'True') {
                scope.class = 'md-warn';
                scope.iconname = 'warning';
            } else {
                scope.class = 'md-accent';
                scope.iconname = scope.icon;
            }
        }
    };
}
